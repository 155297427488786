<template>
  <section class="search--hide">
    <form class="search--form" @click.stop>
      <fieldset class="search--form__label">
        <input
          v-focus
          v-model="data"
          type="text"
          name="text"
          id="search--form"
          required
          @keypress.enter.prevent
        />
        <label for="search--form">{{ t("Please enter search words") }}</label>
      </fieldset>

      <router-link
        :to="{ name: 'Search', query: { query: data } }"
        tag="button"
        type="submit"
        class="search--form__btn"
        aria-label="Шукати"
      >
        {{ t("find") }}
      </router-link>
    </form>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      data: "",
    };
  },
});
</script>

<style lang="scss">
@import "~@/assets/styles/_mixins.scss";

.search--hide {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 0.3s linear;

  @media (hover: hover) {
    pointer-events: none;
  }

  &.showFormHeader {
    position: fixed;
    @media (hover: hover) {
      pointer-events: all;
    }
  }

  &.showFormFooter {
    position: absolute;
    @media (hover: hover) {
      pointer-events: all;
    }
  }
}
.search--form {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 15px;
  background-color: #000;
  color: #fff;
  @include property("padding-top", 80, 25, true, 1280, 576);
  @include property("padding-bottom", 80, 25, true, 1280, 576);

  .showFormHeader & {
    top: 105px;

    @media (max-width: 575.98px) {
      top: 68px;
    }
  }

  .showFormFooter & {
    bottom: calc(247px - 15px);

    @media (max-width: 1279.98px) {
      bottom: calc(
        calc(280px + (300 - 280) * ((100vw - 1024px) / (1280 - 1024))) - 15px
      );
    }

    @media (max-width: 1023.98px) {
      bottom: calc(315px - 10px);
    }

    @media (max-width: 767.98px) {
      bottom: calc(450px - 10px);
    }

    @media (max-width: 479.98px) {
      bottom: calc(
        calc(580px + (450 - 580) * ((100vw - 320px) / (480 - 320))) - 15px
      );
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  &__label {
    position: relative;
    max-width: 812px;
    width: 100%;

    input {
      font-size: 18px;
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      padding: 7px 0;
      width: 100%;
      border-bottom: 0.5px solid #e1e1e1;

      &:focus,
      &:valid {
        ~ label {
          top: -5px;
        }
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
      @include property("font-size", 18, 14, true, 576, 320);
      line-height: calc(23 / 18);
      font-style: italic;
      letter-spacing: 0.9px;
      color: #e1e1e1;
      transition: all 0.3s linear;
      cursor: text;
    }
  }

  &__btn {
    padding: 4px 17px 5px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-left: 20px;
    transition: all 0.3s linear;

    @media (max-width: 575.98px) {
      margin-left: 0;
      margin-top: 15px;
    }

    @media (hover: hover) {
      &:hover {
        color: #f9b80e;
        border-color: #f9b80e;
      }
    }
  }
}
</style>
